import React, { Suspense } from 'react';
import {
  RouterProvider,
} from 'react-router-dom';
import router from 'routes';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <React.StrictMode>
      <Suspense fallback={<div>Carregando...</div>}>
      <RouterProvider router={router} />
      <Toaster />
      </Suspense>
    </React.StrictMode>
  );
}

export default App;
