import React, { lazy } from 'react';
import {
  createBrowserRouter,
} from 'react-router-dom';

// import Painel from 'pages/painel';
// import Layout from 'layout/layout_sidebar';
// import LayoutBlank from 'layout/layout_blank';
// import ErrorPage from 'pages/404';
// import Disciplinas from 'pages/disciplinas';
// import Material from 'pages/disciplinas/material';
// import Plano from 'pages/plano';
// import Diario from 'pages/diario';
// import User from 'pages/user';
// import Login from 'pages/auth/login';

const Painel = lazy(() => import('pages/painel'));
const Layout = lazy(() => import('layout/layout_sidebar'));
const LayoutBlank = lazy(() => import('layout/layout_blank'));
const ErrorPage = lazy(() => import('pages/404'));
const Disciplinas = lazy(() => import('pages/disciplinas'));
const Material = lazy(() => import('pages/disciplinas/material'));
const Plano = lazy(() => import('pages/plano'));
const Diario = lazy(() => import('pages/diario'));
const Assinatura = lazy(() => import('pages/user/assinatura'));
const Login = lazy(() => import('pages/auth/login'));
const LoginGoogle = lazy(() => import('pages/auth/retorno-google'));
const Logout = lazy(() => import('pages/auth/logout'));
const PrimeiroAcesso = lazy(() => import('pages/tutorial/primeiro-acesso'));
const AdminPlanos = lazy(() => import('pages/admin/plano'));
const MeuEdital = lazy(() => import('pages/edital'));
const Paywall = lazy(() => import('pages/assinatura/paywall'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Painel />,
      },
      {
        path: '/painel',
        element: <Painel />,
      },
      {
        path: '/diario',
        element: <Diario />,
      },
      {
        path: '/disciplinas',
        element: <Disciplinas />,
      },
      {
        path: '/disciplinas/material',
        element: <Material />,
      },
      {
        path: '/plano-de-estudo',
        element: <Plano />,
      },
      {
        path: '/meu-edital',
        element: <MeuEdital />,
      },
      {
        path: '/assinatura',
        element: <Assinatura />,
      },
      {
        path: '/sair',
        element: <Logout />,
      },
    ],
  },
  {
    path: '/',
    element: <LayoutBlank />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/entrandogoogle',
        element: <LoginGoogle />,
      },
      {
        path: '/primeiroacesso',
        element: <PrimeiroAcesso />,
      },
      {
        path: '/assinar',
        element: <Paywall />,
      },
      {
        path: '/assinaturaconfirmada',
        element: <Paywall />,
      },
      {
        path: '/admin/planos',
        element: <AdminPlanos />,
      },
    ],
  },
]);

export default router;
